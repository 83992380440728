body {
    background-color: black;
    color: white;
    font-family: 'Tiny5', 'Courier New', Courier, monospace;
}

.App {
    text-align: center;
    padding: 0 20px 20px;
}

h1, h2 {
    border-bottom: 1px solid white;
    padding-bottom: 5px;
}

a {
    color: white;
}

button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Tiny5', 'Courier New', Courier, monospace;
}

button:hover {
    background-color: gray;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    padding: 5px 0;
    font-family: 'Tiny5', 'Courier New', Courier, monospace;
}

#physics-canvas-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: .8;
}

.modifier-container {
    margin-top: 10px;
}

.modifier-input,
.modifier-reset {
    margin-left: 5px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #f0f0f0;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
    border: none;
    font-family: 'Tiny5', 'Courier New', Courier, monospace;
}

.modifier-input {
    width: 2rem;
}

.modifier-input:focus,
.modifier-input:hover {
    background-color: #ddd;
}

.total {
    font-size: 2rem;
    margin-top: 10px;
    margin-bottom: 0;
    color: yellow;
}

.total::before {
    content: 'Total: ';
}

.radio-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.radio-group label {
    margin-right: 10px;
    cursor: pointer;
}

.radio-group input[type="radio"] {
    margin-right: 5px;
}

.results-dice {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.results-dice__discarded {
    text-decoration: line-through;
}

.history-total {
    color: yellow;
}